.container{
    display : flex;
    flex-direction: column;

    h3{
        color : black;
        font-family: "Silverado Light";
    }

    .alreadyHaveAnAccount{
        p{
            margin:0;
        }
        .bold{
            font-weight: 700;
        }
    }
}