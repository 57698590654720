@import '../../sheetBase.scss';

.twoColumnLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    column-gap: 20px;
}

.firstRow {
    margin-bottom: 20px;
}

.secondRow {
    display: grid;
    grid-template-columns: 2fr 1fr;

    .secondRowLeft {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

.thirdRow {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
