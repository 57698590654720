%baseCenteredForm{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.settlementForm{
    @extend %baseCenteredForm;
}

.survivorForm{
    @extend %baseCenteredForm;
}

.genericContainer{
    width: min-content ;
}

%toolbarBase {
    width: 900px;
    height: 30px;
    float: left;
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: left;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 0 0 3000px rgb(0 0 0 / 60%);
}

.survivorToolbar{
    margin-left:9px;
    float:none;
    width:auto;
}

.settlementSelect{
    height: 27.5px;
    margin:1px;
}

.survivorPagination{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;

    .button{
        
        flex-grow: 1;
        background: none!important;
        border: none;
        padding: 0!important;
        /*optional*/
        font-family: arial, sans-serif;
        /*input has OS specific font-family*/
        color: #069;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}