@import '../styles/base-styles.scss';

.header {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
    gap: 18px;
    margin-top: -7px;

    .survivalLimitVerticalLine {
        @include verticalLine(1px, 42px, $lineColor);
    }
}

.settlementNameInput{
    padding-top: 3px;
    margin-left: 2px;
}

.survivalLimitBoxContainer {
    display: flex;
    align-items: center;
    margin-top: -4px;
    gap: 10px;

    .survivalLimitBox {
        @include borders(1px);
        width: 40px;
        height: 40px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        .survivalLimitInput {
            text-align: center;
            font-size: 2rem;
            width: 100%;
        }
    }

    .survivalLimitText {
        @include silveradoFont;
        font-size: 17px;
    }
}

.settlementNameLabelContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 2px;
    gap: 2px;

    .settlementNameLabel {
        display: grid;
        grid-template-columns: max-content 1fr;
        @include silveradoFont;
        font-size: 17px;
        margin: 0;
    }

    .settlementNameLine {
        @include horizontalLine(1px, 325px, $lineColor);
    }

    .settlementNameHelperText {
        font-size: 7px;
        margin: 0;
    }
}

.deathCountContainer {
    margin-top: 3px;
}
