@import '../../sheetBase.scss';

.header {
    display: flex;
    flex-direction: column;

    .headerTitle {
        @include silveradoFont;
        font-size: 16.8px;
    }

    .headerHelper {
        @include amplitudeWideBook;
        font-size: 8px;
    }
}

.upperHalf {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.secondRow {
    margin-bottom: 15px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.monsterRow {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;

    .monsterRowRight {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 16px;
    }

    .nemesisContainer{
        margin-top: auto;
        margin-bottom: 0px;
    }

    .notesContainer{
        margin-top: auto;
        margin-bottom: 0px;
    }
}
