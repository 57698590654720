@import '../sheetBase.scss';

.survivorTable {
  position: relative;

  .survivorTableHeader {
    display: grid;
    grid-template-columns: 200px 50px 1fr;
    height: 20px;

    .label{ 
        @include amplitudeWideBook;
        font-weight: 600;
        font-size: 8px;
    }

    .gender {
      position: relative;
      left: 5px;
    }

    .notes {
      position: relative;
      left: 8px;
    }
  }

    .survivorTableInnerContainer {
      display: flex;
      flex-direction: column;
    }

    .survivorTableRowSeparator{
        $width : 100%;
        height: 17px;

        @include horizontalLine(1px, $width, $lineColor);
        &.first{
          height: 4px;
            @include horizontalLine(1.5px, $width);
        }
    }

    .surivorTableColumnSeparator{
        @include verticalLine(1px, 444px, $lineColor);
        position: absolute;

        &.first{
          top: 1%;
          left: 190px;
        }

        &.second{
          top: 1%;
          left: 250px;
        }
    }
}

.survivor_row {
  width: inherit;
  height: inherit;
  position: relative;
  z-index: 999;
  &:hover {
      background-color: #ddd;
  }
}

.survivor_row.dead {
  text-decoration: line-through;
  text-decoration-color: red;
}

.deadSurvivor {
    text-decoration: line-through;
    text-decoration-color: red;
  }

.view_survivor_button {
    background-position: center;
  width: 16.9px;
  height: inherit;
  float: left;
  background-image: url('../../images/eye.png');
  background-size: contain;
  margin-left: -15px;
  background-repeat: no-repeat;
  border: 0;
  &:focus {
    outline: none !important;
    border: none;
  }
}

.survivor_name {
    float: left;
    width: 196px;
    height: inherit;
    overflow: hidden;
    text-decoration: inherit;
    text-decoration-color: inherit;
}

.survivor_gender {
    float: left;
    width: 48px;
    text-align: center;
    height: inherit;
    text-decoration: inherit;
    text-decoration-color: inherit;
}

.survivor_notes {
  float: left;
  width: 514px;
  height: inherit;
  overflow: hidden;
  text-decoration: inherit;
  text-decoration-color: inherit;
  padding-left: 7px;
}
