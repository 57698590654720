@import '../styles/base-styles.scss';

.lanternResearchContainer {
    @include borders(1px);
    height: 190px;
    width: 214px;

    .headerWrapper {
        @include borders(1px);
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        height: 65px;
        padding: 0px 10px;

        .header {
            width: max-content;
            .headerHelper {
                width: 150px;
            }
        }
    }

    .laternInnerContainer {
        padding: 10px 15px;
        .lanternResearchMonsterVolume {
            @include silveradoFont;
            font-size: 16.8px;
            margin-bottom: -8px;
        }

        .horizontalLine {
            @include horizontalLine(1px, 186px, $lineColor);
            height: 20px;
            .volumeInput {
                height: 100%;
                width: 100%;
            }
        }
    }

    .inputContainer {
        @include borders(1px);
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .inputField {
            text-align: center;
            width: 100%;
        }
    }
}
