$navBarWidth: 250px;
$contentMargin: 20px;
$contentSideMargin: 80px;
$contentSideMarginPhone: 30px;

@mixin phone {
    @media (max-width: 640px){
      @content;
    }
  }

.main {
    margin-left: 0;

    .content {
        margin: $contentMargin;
        margin-left: $contentSideMargin;
        margin-right: $contentSideMargin;

        @media only screen and (max-width: 600px) {
            margin-left: 30px;
            margin-right: 30px;
         }

         @include phone{
            margin: 36px
         }
    }

    @media only screen and (min-width: 768px) {
       // margin-left: $navBarWidth;
        $contentMargin: 25px;
        $contentSideMargin: 100px;
    }
}

.sideNavContainer {
    position: fixed;
    z-index: 1;
    top: 64px;
    left: 0;
    background-color: #3b3b3b;
    height: 48px;
    width: 100%;
    transition: 0.5s;

    @include phone{
        height: 64px;
        overflow-x: auto;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.chevron {
    display: none;
    cursor: pointer;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
        display: block;
    }
}
