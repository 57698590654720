@import '../styles/base-styles.scss';

.deathCounterContainer {
    display: flex;
    flex-direction: column;
    width: 252p;
    gap: 4px;

    .deathCountLabel {
        @include silveradoFont;
        font-size: 17px;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .deathCountLine {
        @include horizontalLine(1px, 100%, $lineColor);
    }

    .deathCountCheckboxWrapper {
        display: grid;
        column-gap: 2.5px;
        row-gap: 2px;
    }

    &.ark {
        width: 376px;
    }
}
