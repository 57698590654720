.toolbar{
    width: 900px;
    height: 30px;
    float: left;
    margin-bottom: 10px;
    text-align: left;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 0 0 3000px rgb(0 0 0 / 60%);
    align-items: center;

    .right{
        display:flex;
        column-gap: 4px;
        align-items: center;
    }

    .left{
        display:flex;
        column-gap: 4px;
        align-items: center;
    }

    .divider{
        border-left: 1px solid #fff;
        height: 22px;
        margin: 2px 0px 2px 2px;
    }
}