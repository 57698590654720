.bottomBar{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    background-color: #3B3B3B;

    .container{
        margin-right: 10px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}