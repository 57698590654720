.gear {
    display: flex;
    flex-direction: column;
    margin-left: -100px;
}

.gearRow {
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 600px;
}

$containerSize : 195px;
$overlayPart : 16px;

.container{
    width: $containerSize;
    height: $containerSize;
    overflow: hidden;
    margin: 2.5px;
}

.item{
    background-color: gray;
    background-size: contain;
    background-repeat: no-repeat;
    width: calc($containerSize + 2 * $overlayPart);
    height: calc($containerSize + 2 * $overlayPart);
    margin-top: -$overlayPart;
    margin-left: -$overlayPart;
}