@import '../../sheetBase.scss';

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.survivorTable {
    margin-top: 10px;
}

.survivorHeader {
    display: flex;
    flex-direction: column;

    .survivorTitle {
        display: flex;
        align-items: center;
        gap: 6px;
        .timeLineLabel {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .columnLabels {
        display: grid;
        grid-template-columns: 240px 35px 40px 174px 35px 170px 35px 35px;
        span {
            font-size: 8px;
            font-weight: bold;
        }
    }
    .horizontalLine {
        @include horizontalLine(2px, 100%, black);
    }
}

.survivorBody {
    display: flex;
    flex-direction: column;

    .survivorCheckbox {
        width: 12px;
        height: 12px;
    }

    .rowContainer{
        height: 22px;
    }

    .row {
        height: 22px;
        display: grid;
        grid-template-columns: 230px 40px 40px 170px 40px 170px 35px 35px;
        align-items: center;

        &:hover {
            background-color: #ddd;
        }

        @include horizontalLine(1px, 100%, $lineColor);
    }

    .strikeLine{
        @include horizontalLine(2px, 100%, red);
        height: 11px;
        margin-top: -22px;
    }

    .rowItem {
        width: 100%;
        height: 100%;
        border-right: 1px solid $lineColor;
    }

    .survivorDead,
    .survivorRetired {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .smallLabel {
        input {
            text-align: center;
            width: 36px;
        }
    }

    .survivorSex {
        text-align: center;
    }
}

.createBar {
    margin-top: -48px;
    float: right;

    .createButton {
        margin-top: 2px;
        margin-left: 4px;
        width: 22px;
        height: 22px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFEQ4J////r2u/CAAAAAJ0Uk5T/wDltzBKAAAAIklEQVR42mJgxAkYiJJiAIPhI8WAFQwmqeEa8mSkQ4AAAwBpHAHBLsjALQAAAABJRU5ErkJggg==');
        background-size: contain;
    }
}
