.productKeyText {
    width: 98%;
    margin-right: 10px;
    font-weight: 400;
    height: 30px;
    box-shadow: none;
    border-bottom: 1px solid gray;

    &:focus{
        border-bottom: 1px solid lightgray;
    }
}

.productKeyBtn {
    background-color: teal;
    color: white;
    border: none;
    font-weight: 400;
    height: 30px;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 8px;

    &:disabled {
        background-color: gray;
    }
}
