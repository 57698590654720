@mixin phone {
    @media (max-width: 640px){
      @content;
    }
  }

  @mixin sidePadding($padding) {
    padding-left: $padding;
    padding-right: $padding;
  }

.container{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: stretch;
    min-height: 300px;
    height: 300px;
    width: 100%;

    @include phone{
        flex-direction: column;
        height: unset;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .img{
        aspect-ratio: 1 / 1;
        div {
            width: 95%;
            height: 95%;
            margin-left: 2.5%;
            margin-top: 2.5%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: calc(80% - 300px);
        @include phone {
            width: 100%;
         }

        .middleContainer{
            display: flex;
            flex-direction: column;
            width: calc(80% - 300px);
            justify-content: center;
            row-gap: 10px;
            font-size: 14px;
            padding-left: 30px;
            height: 80%;

            @include phone {
                width: 100%;
                @include sidePadding(30px);
             }
        
        }

        .backerkitInfo {
            padding-left: 30px;
            color : #e9e9e9;
            display: flex;
            flex-direction: column;
            justify-content: end;

            @include phone{
                text-align: center;
            }
        }

        .header{
            font-size: 26px;

            @include phone {
                text-align: center;
             }
        }

        .selectContainer{
            .label{
                font-weight: bold;
                width: 60px;
            }
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            select{
                min-width: 200px;
            }

            @include phone {
                flex-direction: column;
                padding-bottom: 20px;

             }
        }
    }

    .pledge{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20%;

        .label{
            font-weight: bold;
        }

    }
}