.list {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

@mixin phone {
    @media (max-width: 640px){
      @content;
    }
  }

$backerKit-base-color: #FFCC56;
$backerKit-dark-color: #e7b94f;

.backerKitButton {
    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2px;
    }

    appearance: none;
    background-color: $backerKit-base-color;
    border: 1px solid $backerKit-dark-color;
    border-radius: 6px;
    box-shadow: $backerKit-dark-color 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;

    &:focus:not(:focus-visible):not(.focus-visible) {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        background-color: $backerKit-dark-color;
    }

    &:focus {
        box-shadow: $backerKit-dark-color 0 0 0 3px;
        outline: none;
    }

    &:disabled {
        background-color: #c4c4c4;
        border-color: rgba(27, 31, 35, 0.1);
        color: rgb(255, 255, 255);
        cursor: not-allowed;

        &:active {
            background-color: #c4c4c4;
            box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
        }
    }

    &.fakeDisable{
        background-color: #c4c4c4;
        border-color: rgba(27, 31, 35, 0.1);
        color: rgb(255, 255, 255);
        cursor: not-allowed;

        &:active {
            background-color: #c4c4c4;
            box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
        }
    }

    &:active {
        background-color: $backerKit-base-color;
        box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
    }
}

.titleContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .rightSide{
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}


.title {
    width: 100%;
    text-align: left;
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;

    @include phone{
        text-align: center;
    }
}

.underTitleRule{
    width: calc(100% - 5px);
    height: 1px;
    background-color: rgb(217, 217, 217);
    
    margin-bottom: 20px;
    margin-left: 5px;
}

.line {
    height: 1px;
    margin: 8px;   
    
    @include phone{
        margin: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.container {
    display: relative;

    .bottomBar {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 60px;
        background-color: #3b3b3b;

        .container {
            margin-right: 10px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.faqContainer {
    display: flex;
    flex-direction: column;

    .wrapCollabsible {
        margin: 1.2rem 0;

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            font-size: 2.5rem;
        }
    }
    .lblToggle {
        display: block;
        font-weight: bold;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        padding: 1rem;
        color: #3b3b3b;
        cursor: pointer;
        transition: all 0.25s ease-out;
    }

    .collapsibleContent {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
    }
    .collapsibleContent.show {
        max-height: 350px;
    }
    .toggle:checked + .lblToggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .collapsibleContent .contentInner {
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
    }
    .collapsibleContent p {
        margin-bottom: 0;
    }
}
