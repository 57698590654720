@import '../styles/base-styles.scss';

.timeline {
    & .timeLineHeader {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 1px;
    }

    & .timeLineLabel {
        @include silveradoFont;
        font-size: 16px;
    }

    & .timelineTableHeader {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    & .timelineTableHeaderLabels {
        @include amplitudeWideRegular;
        font-size: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 5px;
    }

    & .timeLineBoldLine {
        @include horizontalLine(2px, 100%, $lineColor);
    }

    & .timeLineContainer {
        & .verticalSeparator {
            @include verticalLine(1px, 12px);
        }

        & .seIcon {
            @include amplitudeWideIcon;
            font-size: 12px;
            &.underTen{
                margin-left: 3px;
            }
        }

        & .timeLineEventWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: column;
        }

        & .timeLineEventInput {
            font-size: 14px;
        }

        & .timeLineEventContainer {
            font-size: 8px;
            display: grid;
            grid-template-columns: 6px 15px 5px 1fr;
            height: 22px;
            align-items: center;
            border-bottom: 1px solid $lineColor;

            &:nth-child(n + 10) {
                grid-template-columns: 6px 15px 8px 1fr;
            }

            & .timeLineEventLabelContainer {
                display: grid;
                align-items: center;
                grid-template-columns: max-content 1fr;

                & .timeLineEventInput {
                    font-size: 14px;
                    min-width: 0;
                    max-width: 100%;
                }
            }

            & .timeLineEventLabel {
                @include amplitudeWideMedium;
                display: flex;
                align-items: center;
                white-space: nowrap;
                width: 100%;
                line-height: 11px;

                & span.icon {
                    @include amplitudeWideIcon;
                    margin: 0 1px;
                    font-size: 10px;
                    line-height: 11px;
                    display: inline-block;
                    width: 18px;
                    text-align: center;
                }
            }
        }
    }

    .timeLinePrologue {
        span {
            font-family: Raleway;
            font-weight: bold;
            font-size: 10px;
        }
        border-bottom: 1px solid $lineColor;
        display: flex;
        gap: 20px;
        padding: 2px 0px;
    }

    //  potsun timeline styles
    &.potstar {
        .timeLineEventContainer {
            height: 23px;
        }
        .timeLineHeader {
            margin-bottom: 7px;
        }
    }

    &.core {
        .timeLineEventLabel {
            margin-left: 2px;
        }
    }

    &.potsun {
        .timeLineEventContainer {
            height: 24px;
        }
    }

    &.ark {
        .timeLineContainer {
            .seIcon {
                margin-right: 3px;
            }
        }
        .timeLineEventLabel {
            margin-left: 3px;
            .bookIcon {
                @include amplitudeWideIcon;
                margin: 0px;
                font-size: 6px;
                line-height: 11px;
                display: inline-block;
                width: 14px;
                text-align: center;
            }
        }
        .timeLineEventContainer {
            height: 22.5px;
        }
    }
}

.timelineSmall {
    width: 490px;
}

.timelineFull {
    width: 100%;
}
