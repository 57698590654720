@import '../customer-cp.module.scss';

/* The side navigation menu */
.sidenav {
    height: 100%;
    overflow-y: hidden; /* Disable vertical scroll */
    display: flex;
    flex-direction: row;
    width: 100%;

    @include phone {
        width: fit-content;
    }
    
    align-items: center;
    justify-content: center;

    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 14px;
        color: #818181;
        display: block;
        transition: 0.3s;

        @include phone {
            padding: 16px;
        }

        div {
            display: flex;
            flex-direction: column;
            column-gap: 2px;
            justify-content: left;
            align-items: start;
            text-align: center;

            p:nth-child(1){
                margin-top: -6px;
            }
        }

        &:hover {
            color: #f1f1f1;
        }

        &.active {
            color: white;
        }

        &.disabled {
            pointer-events: none;

            &:hover {
                color: #818181;
            }
        }
    }

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    .downloadContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        margin-top: auto;
        a {
            font-size: 14px;
            padding: 8px;
        }
    }
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left 0.5s;
    padding: 20px;
}

@media screen and (max-height: 450px) {
    .sidenav a {
        font-size: 14px;
    }
}
