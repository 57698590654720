.panelHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Silverado Light';
    flex-direction: column;
    
    font-size: 24px;

    div.headerText {
        width: 100%;
        vertical-align: middle;
        text-align: center;
        font-size: 28px;
        &.second{
            margin-top: -10px;
            font-size: 24px;
        }
    }
}