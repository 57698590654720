@import '../styles/base-styles.scss';

.populationContainer{
    display:  grid;
    grid-template-columns: max-content 150px;
    align-items: center;
    gap: 8px;
}

.inputContainer {
  @include borders(1px);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inputField {
      text-align: center;
      width: 100%;
  }
}

