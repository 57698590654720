@import '../styles/ark-settlement-sheet.module.scss';

.header {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .headerTitle {
        font-weight: bold;
        margin-bottom: 2px;
    }

    .horizontalLine {
        @include horizontalLine(2px, 100%, black);
    }
    .headerSubtitle {
        font-size: 8px;
    }
}

.upperHalf {
    display: grid;
    grid-template-columns: 440px 1fr;
    gap: 20px;
}

.sheetTitleContainer {
    display: grid;
    grid-template-columns: max-content 1fr;
    width: 100%;
    gap: 10px;
    align-items: center;
    margin-top: 3px;


    .cognitionBox {
        @include borders(1px);
        width: 40px;
        height: 40px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        .cognitionInput {
            text-align: center;
            font-size: 2rem;
            width: 100%;
        }
    }

    .sheetTitleInnerContainer {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        margin-top: -5px;
        gap: 2px;

        .sheetlabel {
            display: grid;
            grid-template-columns: max-content 1fr;
            margin: 0;
            span {
                @include silveradoFont;
                font-size: 17px;
                font-weight: bold;
            }
        }

        .horizontalLine {
            @include horizontalLine(2px, 100%, black);
        }

        .helperText {
            font-size: 8px;
            margin: 0;
        }
    }
}

.boneEatersContainer{
    margin-top: 2px;
    .title{
        @include silveradoFont;
        font-size: 17px;
        font-weight: bold;
    }

    .boneEaterRow{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 0px;
        p{
            font-size: 10px;
            font-weight: bold;
            font-family: Raleway;
        }
        .boneEaterLevels{
            display: flex;
            flex-direction: row;
            gap: 8px;

            .boneEaterLevel{
                display:flex;
                flex-direction: row;
                gap: 4px;
            }
        }
    }
    

    .narrowHorizontalLine{
        @include horizontalLine(1px, 100%, black);
    }

    .horizontalLine {
        @include horizontalLine(2px, 100%, black);
    }   

    .checkboxContainer {
        position: relative;
        cursor: pointer;
        width: 11px;
        height: 11px;
    
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
    
            &:checked ~ span {
                box-shadow: inset 0px 0px 0px 30px #000;
            }
    
            &:checked ~ span:after {
                display: block;
            }
        }
    
        span {
            position: absolute;
            top: 0;
            left: 0;
            height: inherit;
            width: inherit;
            border: 1px solid black;
            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }
    
        &:hover input ~ span {
            background-color: #ccc;
        }
    }
}
.cognitionContainer {
    .header {
        margin-bottom: 5px;
    }
    .reward {
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: center;
        font-size: 10px;
        font-weight: bold;
        height: 25px;
        border-bottom: 1px solid $lineColor;
    }
}

.knowledgeContainer {
    margin-top: 20px;
}

.settlementVictoriesContainer {
    margin-top: 12px;
    margin-bottom: 20px;
    width: 100%;
    height: 330px;
    padding: 15px;
    @include borders(1px);

    .settlementVictoriesTitle {
        .settlementTitle {
            font-size: 16px;
            font-weight: bold;
        }
        .settlementSubtitle {
            font-size: 8px;
            font-weight: 500;
        }
    }

    .settlementQuarries,
    .nemesisContainer {
        margin-top: 10px;

        .headerInnerContainer {
            display: grid;
            grid-template-columns: 220px 1fr;
            align-items: end;
        }

        .columnLabels {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            div {
                display: flex;
                font-size: 8px;
                font-weight: bold;
                flex-direction: column;
                align-items: center;
                span {
                    margin-top: -2px;
                }
            }
        }

        .quarryContainer {
            display: grid;
            grid-template-columns: 210px 1fr;
            gap: 20px;
            margin-top: 6px;
            .quarryRowContainer {
                margin-top: 3px;
            }
            .quarryRow {
                width: 100%;
                height: 15px;
                display: grid;
                margin-left: 10px;
                grid-template-columns: 45px 1fr 1fr 1fr;
                .quarryCheckBox {
                    width: 9px;
                    height: 9px;
                }
                .quarryRowItem {
                    display: flex;
                    gap: 2px;
                }
            }
        }
    }

    .nemesisContainer {
        margin-top: 5px;
    }
}
