@import '../styles/base-styles.scss';

.principlesContainer {
    .principlesHeader {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 5px;

        .principlesHeaderTitle {
            @include silveradoFont;
            font-size: 16.8px;
            margin: 0;
        }

        .principlesHelper {
            @include amplitudeWideBook;
            font-size: 8px;
            margin: 0;
        }
    }

    .principlesInnerContainer {
        width: 50%;
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 10px;
    }

    .principlesHorizontalLine {
        width: 366px;
        @include horizontalLine(1px, 366px, $lineColor);

        .principleInput {
            height: 100%;
            width: 100%;
        }
    }

    .principleContainer {
        width: 366px;
        height: 18px;
        line-height: 18px;
        display: grid;
        grid-template-columns: 40% 60%;

        .principleName {
            @include amplitudeWideMedium;
            margin: 0;
            font-size: 8px;
        }

        .principleOptionContainer {
            display: grid;
            grid-template-columns: 1fr 15px 1fr;
            align-items: center;
            gap: 5px;

            .orOption {
                left: 254px;
                @include amplitudeWideMedium;
                font-size: 8px;
            }

            .principleOption {
                @include amplitudeWideBook;
                font-size: 8px;
                display: grid;
                grid-template-columns: 17px 1fr;
                align-items: center;
                .principleOptionCheckbox {
                    width: 8px;
                    height: 8px;
                }

                .principleOptionInput{
                    width: 79px;
                    margin-left: -2px;
                }
            }
        }
    }

    .extraPrinciple {
        width: 100%;
        height: 16px;
        font-size: 0.8em;
    }
}
