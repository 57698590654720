@import '../../sheetBase.scss';

.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .headerTitle {
            font-weight: bold;
            margin-bottom: 2px;
        }

        .horizontalLine {
            @include horizontalLine(2px, 100%, black);
        }
        .headerSubtitle {
            font-size: 8px;
        }
    }
    .principleContainer {
        .header {
            margin-bottom: 5px;
        }
    }
    .seedsContainer {
        .header {
            margin-bottom: 5px;
        }
    }
    .settlementContainer {
        .header {
            margin-bottom: 14px;
        }
    }

    .resourceContainer {
        grid-column: span 2;
        .header {
            margin-bottom: 0px;
            .headerTitle {
                margin-bottom: 0px;
            }
        }
        .resourceColumnNames {
            display: grid;
            grid-template-columns: repeat(5, 155px);
            span {
                font-size: 8px;
                font-weight: bold;
            }
        }
    }

    .lastRow {
        grid-column: span 2;
        display: grid;
        grid-template-columns: 550px 1fr;
        gap: 20px;
    }
}
