@import '../../../sheetBase.scss';

.pageContainer {
    @include borders(1.5px);
    background-color: white;
    width: 640px;
    height: 640px;
    font-family: Raleway;

    .inputContainer {
        @include borders(1px);
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .inputField {
            width: 100%;
            height: 100%;
        }
    }

    .innerContainer {
        padding: 25px;
        $firstColumnLeft: 26px;
        $secondColumnLeft: calc($firstColumnLeft + 308px);

        .saviorSection{
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 6px;
            margin-left: 4px;
            width: 2000px;

            .or{
                @include amplitudeWideBook;
                font-size: 8px;
                line-height: 8px;
                margin-top: -1px;
            }

            .saviorElement{
                display: flex;
                flex-direction: row;
                column-gap: 3px;
                align-items: center;
                p{
                    @include amplitudeWideBook;
                    font-size: 6.5px;
                }
                .checkbox{
                    width: 8px;
                    height: 8px;
                    margin-top: -1px;
                }
            }
        }

        input {
            border: none;
            background-color: transparent;
        }

        p {
            margin: 0px;
        }

        .checkboxContainer {
            display: block;
            cursor: pointer;
            width: 11px;
            height: 11px;
            position: relative;
            &:hover input ~ span {
                background-color: #ccc;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ span {
                    box-shadow: inset 0 0 0 8px #000;
                }

                &:checked ~ span:after {
                    display: block;
                }
            }

            span {
                position: absolute;
                top: 0;
                left: 0;
                height: inherit;
                width: inherit;
                border: 1px solid black;
                &:after {
                    content: '';
                    position: absolute;
                    display: none;
                }
            }
        }

        .boldCheckboxContainer {
            outline: solid 3px black;
            outline-offset: -3px;
        }

        .roundCheckbox {
            position: relative;
            top: 3px;
            label {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 50%;
                cursor: pointer;
                height: 12px;
                left: 0;
                position: absolute;
                top: 0;
                width: 12px;
                &:after {
                    border: 1.5px solid #fff;
                    border-top: none;
                    border-right: none;
                    content: '';
                    height: 4px;
                    left: 2px;
                    opacity: 0;
                    position: absolute;
                    top: 2px;
                    transform: rotate(-45deg);
                    width: 7px;
                }
            }
        }

        .roundCheckbox input[type='checkbox'] {
            visibility: hidden;
        }

        .roundCheckbox input[type='checkbox']:checked + label {
            background-color: #000;
            border-color: #000;
        }

        .roundCheckbox input[type='checkbox']:checked + label:after {
            opacity: 1;
        }

        .firstRow,
        .secondRow,
        .thirdRow,
        .fourthRow {
            display: flex;
            justify-content: space-between;
        }

        .firstRow{
            height: 44px;
        }

        .secondRow,
        .thirdRow,
        .fourthRow {
            margin-top: 10px;
        }

        .nameContainer {
            width: 280px;

            .nameInnerContainer {
                display: flex;
                justify-content: space-between;
            }

            .nameInputContainer {
                display: flex;
                align-items: center;
                gap: 2px;
                .name {
                    @include silveradoFont;
                    font-size: 17px;
                }
            }

            .genderCheckboxContainer {
                display: flex;
                gap: 5px;
                align-items: center;
            }

            .nameSeparator {
                @include horizontalLine(2px, 280px);
            }

            .nameHelper {
                @include amplitudeWideBook;
                font-size: 8.6px;

                width: 180px;
            }

            .nameContainerSecond {
                margin-top: 4px;
                display: flex;
                width: 280px;
                justify-content: space-between;
            }

            .nameContainerThird {
                margin-top: 2px;
                display: flex;
                width: 280px;
                justify-content: space-between;
                align-items: center;
                height: 8px;
                p.title{
                    margin-top: -1px;
                }
            }

            .deadContainer {
                label {
                    margin-top: -2px;
                }

                @include amplitudeWideBook;
                font-size: 8.6px;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 2px;
            }

            .genderLabel {
                @include amplitudeWideMedium;
                font-size: 9px;
                display: flex;
                gap: 2px;
                align-items: center;
            }
        }

        .huntXPContainer {
            width: 280px;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .checkboxHuntXP {
                width: 9px;
                height: 9px;
            }

            .firstLine {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .huntXPHeader {
                    @include silveradoFont;
                    font-size: 13px;
                }

                .huntXCheckBoxContainer {
                    display: flex;
                    gap: 5px;
                }
            }

            .huntXPSeparator {
                @include horizontalLine(2px, 280px);
            }

            .secondLine {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .ageContainer {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    .icon {
                        @include amplitudeWideIcon;
                        font-size: 8px;
                    }

                    p {
                        font-size: 6.8px;
                        @include amplitudeWideBook;
                        &.retired {
                            @include amplitudeWideMedium;
                        }
                    }

                    .blackRectangle {
                        @include blackRectangle;
                        width: 6px;
                        height: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.big {
                            width: 8px;
                            height: 8px;
                        }

                        .circle {
                            width: 3px;
                            height: 3px;
                            border-radius: 4px;
                            background-color: white;
                        }
                    }
                }
            }
        }

        .icon {
            @include amplitudeWideIcon;
        }

        .survivalContainer {
            @include borders(1.5px);
            width: 280px;
            height: 68px;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .survivalLeft {
                display: flex;
                align-items: center;
                gap: 5px;

                .survivalinputcontainer {
                    @include borders(1.5px);
                    width: 42px;
                    height: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .inputField {
                        text-align: center;
                        width: 100%;
                    }
                }

                .survivalLeftLabelContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 40px;

                    .survivalCheckContainer {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                    }

                    .survivalCheckBox {
                        width: 10px;
                        height: 10px;
                    }

                    .lockIcon {
                        @include lockIcon;
                        width: 8px;
                        height: 8px;
                        background-size: 8px 8px;
                    }

                    .survivalHeader {
                        @include silveradoFont;
                        font-size: 17px;
                        margin-top: -5px;
                    }

                    .survivalHelper {
                        @include amplitudeWideBook;
                        font-size: 8.5px;
                    }
                }
            }

            .survivalRight {
                display: flex;
                flex-direction: column;

                .survivalCheckLabelsContainer {
                    display: flex;
                    align-items: center;
                    height: 10px;
                    gap: 3px;
                }

                .survivorCheckLabel {
                    @include amplitudeWideBook;
                    font-size: 8.5px;
                }
                .survivalCheckBoxRight {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        .weaponProficiencyContainer {
            @include borders(1.5px);
            width: 280px;
            height: 68px;
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .weaponProficiencyLeft {
                .weaponProficiencyHeader {
                    @include silveradoFont;
                    font-size: 18px;
                }

                .typeSeparatorContainer {
                    display: flex;
                    align-items: center;

                    .typeLabel {
                        @include amplitudeWideMedium;
                        font-size: 9px;
                    }

                    .typeSeparator {
                        @include horizontalLine(1px, 105px);
                        height: 15px;
                        .weaponInput {
                            position: relative;
                            top: -4px;
                            width: 108px;
                            height: 100%;
                            font-size: 0.7em;
                        }
                    }
                }

                .typeHelper {
                    @include amplitudeWideBook;
                    font-size: 7.2px;
                    position: relative;
                    left: 25px;
                }
            }

            .weaponProficiencyRight {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .checkboxesContainer {
                    display: flex;
                    gap: 2px;
                }

                .checkboxWeaponXP {
                    width: 10px;
                    height: 10px;
                }

                .weaponProficiencyRightInnerContainer {
                    display: flex;
                    justify-content: space-between;
                }

                .specialistContainer {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    .blackRectangle {
                        @include blackRectangle;
                    }

                    p {
                        @include amplitudeWideBook;
                        font-size: 7.2px;
                    }
                }

                .masterContainer {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    .blackRectangle {
                        @include blackRectangle;
                    }

                    p {
                        @include amplitudeWideBook;
                        font-size: 7.2px;
                    }
                }
            }
        }

        .movementAndBrainContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .movementContainer {
                @include borders(1.5px);
                width: 280px;
                height: 68px;
                display: grid;
                grid-template-columns: 1fr repeat(5, max-content);

                .movementLabelContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .movementInputContainer {
                        width: 40px;
                        position: relative;

                        .inputField {
                            text-align: center;
                            font-size: 28px;
                            font-weight: 600;
                            color: lightgray;
                            width: 100%;
                        }
                    }
                    .movementLabel {
                        @include amplitudeWideBook;
                        font-size: 6.5px;
                        display: flex;
                        position: relative;
                        bottom: 6px;
                    }
                }

                .statContainer {
                    height: 68px;
                    width: 46px;
                    display: flex;
                    padding: 7px;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border-left: 1.5px solid black;

                    .statBoxInput {
                        @include borders(1.5px);
                        width: 32px;
                        height: 32px;
                        text-align: center;
                        font-size: 0.65em;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .attribute_count {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            font-size: 12px;
                        }
                    }

                    .statLabel {
                        @include amplitudeWideBook;
                        font-size: 7.2px;
                        text-align: center;
                        width: 48px;
                    }
                }
            }

            .brainContainer {
                @include borders(1.5px);
                width: 280px;
                height: 68px;
                display: grid;
                grid-template-columns: max-content 1fr;

                .brainLeft {
                    width: 52px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    border-right: 1.5px solid black;

                    .insanityLabel {
                        @include amplitudeWideBook;
                        font-size: 8px;
                    }

                    .insanityShield {
                        width: 32px;
                        height: 30px;
                        background-size: 32px 30px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .insanityShieldIcon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-size: 100% 100%;
                        }

                        .insanityCount {
                            position: relative;
                            text-align: center;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .brainRight {
                    position: relative;
                    padding: 10px;

                    .brainRightInnerContainer {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        .brainHeader {
                            @include silveradoFont;
                            font-size: 17px;
                            margin-top: -3px;
                        }

                        .brainHelper {
                            @include amplitudeWideBook;
                            font-size: 8px;
                        }
                    }
                    .braincheckbox {
                        position: absolute;
                        top: 12px;
                        right: 12px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .injuries {
            .injuryContainer {
                width: 280px;
                height: 58px;
                display: grid;
                grid-template-columns: max-content 80px 1fr max-content;
                align-items: center;
                gap: 5px;
                border-bottom: 1px solid black;

                .injuryShield {
                    width: 36px;
                    height: 36px;
                    position: relative;
                    background-size: 36px 36px;
                    .injuryInputContainer {
                        position: relative;
                        z-index: 10;
                        height: 80%;
                        width: 100%;
                        .injuryInput {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                        }
                    }
                    .injuryShieldIcon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-size: 100% 100%;
                    }
                }

                .injuryInnerContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-top: -5px;
                    .titleContainer {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        .icon {
                            @include amplitudeWideIcon;
                            font-size: 9px;
                            text-align: center;
                            vertical-align: middle;
                        }
                    }

                    .helperContainer {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .blackRectangle {
                            width: 8px;
                            height: 8px;
                            box-shadow: inset 0 0 0 1000px black;
                        }
                        .injuryHelper {
                            @include amplitudeWideBook;
                            font-size: 8.4px;
                            line-height: 8px;
                            width: 50px;
                        }
                    }

                    .injuryTitle {
                        @include silveradoFont;
                        font-size: 20px;
                    }
                }

                .injuryCenteralContainer {
                    align-self: flex-start;
                    margin-top: 10px;
                    .injuryProgress {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .injuryProgressCheckboxes {
                            display: flex;
                            flex-wrap: nowrap;
                            gap: 2px;
                            .checkboxContainerInjury {
                                width: 6px;
                                height: 6px;
                            }
                        }
                        .injuryProgressLabel {
                            font-size: 7px;
                            font-weight: 500;
                        }
                    }
                }

                .rightCheckBoxContainer {
                    display: flex;
                    align-self: flex-start;
                    gap: 5px;
                    position: relative;
                    top: 10px;
                    justify-self: end;

                    .checkBoxL,
                    .checkBoxR {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .injuryCheckbox {
                        width: 15px;
                        height: 15px;
                    }

                    .injuryCheckboxBold {
                        outline: solid 4px black;
                        outline-offset: -4px;
                    }

                    .label {
                        @include amplitudeWideBook;
                        font-size: 8.4px;
                    }
                }
            }
        }

        .courageContainer {
            @include borders(1.5px);
            width: 280px;
            height: 146px;
            display: flex;

            .courageLeft {
                border-right: 1px solid black;
            }

            .content {
                width: 140px;
                padding: 8px 5px 8px 8px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-bottom: 1px solid black;

                .courageCheckBoxContainer,
                .understandingCheckBoxContainer {
                    display: flex;
                    gap: 3px;
                    .courageCheckBox,
                    .understandingCheckBox {
                        width: 11px;
                        height: 11px;
                    }
                }

                .infoRow {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    gap: 2px;

                    .infoRowLeft,
                    .infoRowLeft,
                    .infoBlackRectangelContainer {
                        display: flex;
                        align-items: center;
                    }

                    .infoBlackRectangelContainer {
                        display: flex;
                        gap: 2px;
                    }

                    .blackRectangle {
                        @include blackRectangle;
                    }

                    p {
                        @include amplitudeWideMedium;
                        font-size: 7px;

                        &.withBook {
                            margin-left: 2px;
                            margin-top: 0.5px;
                            font-size: 6.8px;
                        }
                    }

                    .icon {
                        @include amplitudeWideIcon;
                        font-size: 8px;
                        margin-left: 2px;
                    }
                }
            }

            .header {
                @include amplitudeWideMedium;
                font-size: 9px;
            }

            .headerBottomSeparator {
                @include horizontalLine(1px, 280px);
            }

            .options {
                padding: 5px;
                .optionsContent {
                    display: flex;
                    flex-direction: column;

                    .row {
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        padding-bottom: 2px;
                    }

                    .optionLabel {
                        font-size: 7px;
                        color: black;
                    }
                    .bottomLine {
                        border-bottom: 1px solid #666666;
                    }
                }
            }
        }

        .horizontalLineForSurvivorNotes {
            @include horizontalLine(1px, 280px);
        }

        .AbilitiesAndDisordersContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .fightingArtsContainer {
            margin-top: 5px;
        }
        .fightingArtsContainer,
        .abilitiesAndImpairmentsContainer,
        .oncePerLifeTimeContainer,
        .disordersContainer {
            .labelContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .headerContainer {
                    display: flex;
                    align-items: center;
                    width: max-content;
                    gap: 5px;

                    .header {
                        @include silveradoFont;
                        font-size: 18px;
                    }

                    .tooltip {
                        @include amplitudeWideBook;
                        margin-top: 8px;
                        font-size: 9px;
                    }
                }
            }

            .horizontalLinesContainer {
                margin-top: -10px;
                .horizontalLine {
                    @include horizontalLine(1px, 280px);
                    height: 18px;
                }
            }

            .helperContainer {
                display: flex;
                align-items: end;
                .lockIcon {
                    @include lockIcon;
                    width: 8px;
                    height: 8px;
                    background-size: 8px 8px;
                }
                p {
                    @include amplitudeWideBook;
                    font-size: 8.6px;
                    height: 10px;
                }
            }
        }

        .oncePerLifeTimeContainer {
            left: $secondColumnLeft;
            top: 572px;

            .header {
                @include silveradoFont;
                font-size: 18px;
                width: 200px;
            }

            .tooltip {
                @include amplitudeWideBook;
                font-size: 9px;
                left: 78px;
                top: 9px;
                width: 100px;
            }

            .reroll {
                @include amplitudeWideBook;
                font-size: 8px;
                top: 11px;
                left: 240px;
                width: 100px;
            }
        }
    }
}

.survivorPagination {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;

    .button {
        width: 100px;
    }
}

.printLayout{
    display: flex;
    flex-direction: row;
    row-gap: 20px;

}