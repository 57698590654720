@import '../../sheetBase.scss';

.lowerHalfContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.nemesisContainer {
    margin-top: 65px;
}

.firstRow {
    display: flex;
    gap: 15px;
}

.milestonesContainer {
    margin-top: 15px;
    .emptyLine {
        display: grid;
        height: 23px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 18px 1fr 95px;
        padding: 0;
        border-bottom: 1px solid $lineColor;

        .emptyLineInput {
            height: 22px;
            width: 252px;
        }
    }
}

.settlementContainer {
    margin-top: 10px;
    .header {
        margin-bottom: 4px;
    }
}

.principlesContainer {
    .principlesHorizontalLine {
        width: 366px;
        @include horizontalLine(1px, 366px, $lineColor);
        margin-top: 5px;
    }

    .principleInput {
        height: 100%;
        width: 100%;
    }
}

.quarryContainer {
    .quarryEmptyLine {
        display: flex;
        width: 165px;
        height: 25px;
        border-bottom: 1px solid $lineColor;

        .emptyLineInput {
            height: 20px;
            width: 252px;
        }
    }
}
