@import '../styles/base-styles.scss';


.notesContainer{
    position: relative;
    top: -5px;
    .horizontalLine{
        @include horizontalLine(1px, 253px, $lineColor);
        height: 16px;
        padding: 0;
        position: relative;
        top: -6px;
        .noteInput {
          height: 100%;
          width: 100%;
        }
    }
}

