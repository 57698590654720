@import '../../sheetBase.scss';

.lowerHalfContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.potsun_icon_container {
    width: 40px;
    height: 45px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2%;
    left: 50%;
    transform: translateX(-55%);

    .horizontalLine {
        box-shadow: inset 0px 0px 0px 1px #000;
        height: 2px;
        width: 100%;
        margin-top: 20px;
    }
}

.firstRow {
    display: flex;
    gap: 15px;

    .nemesisMonstersContainer {
        @include borders(1px);
        margin-top: 65px;
        height: 187px;
        width: 252px;
        padding: 10px;
        position: relative;

        .nemesisHeader {
            display: flex;
            flex-direction: column;
            .headerTitle {
                @include silveradoFont;
                font-size: 16.8px;
                display: flex;
                align-items: center;
                gap: 4px;

                .seIcon {
                    @include amplitudeWideIcon;
                    font-size: 12px;
                }
            }

            .headerHelper {
                font-family: Raleway;
                font-weight: 500;
                font-size: 8px;
            }
        }

        .nemesisMonsterInput {
            position: absolute;
            left: 84px;
            width: 226px;
            height: 21px;
            font-size: 0.8em;
        }

        .horizontalLine {
            position: absolute;
            @include horizontalLine(1px, 230px, $lineColor);
        }

        .nemesisCheckboxContainer {
            width: 8px;
            height: 8px;
        }

        .nemesisInnerContainer {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        .nemesisMonsterRow {
            width: 230px;
            height: 23px;
            position: absolute;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .nemesisMonster {
                @include amplitudeWideBook;
                font-size: 8px;
                width: 120px;
                position: relative;
                left: 12px;
            }

            .nemesisLvl {
                @include amplitudeWideBook;
                position: absolute;
                font-size: 8px;
            }
        }
    }
}

.timelineContainer {
    /* margin-top: 15px; */
}

.milestonesContainer {
    margin-top: 15px;
    .emptyLine {
        display: grid;
        height: 23px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 18px 1fr 95px;
        padding: 0;
        border-bottom: 1px solid $lineColor;

        .emptyLineInput {
            height: 22px;
            width: 252px;
        }
    }
}

.settlementContainer {
    margin-top: 10px;
    .header {
        margin-bottom: 4px;
    }
}

.principlesContainer {
    .principlesHorizontalLine {
        width: 366px;
        @include horizontalLine(1px, 366px, $lineColor);
        margin-top: 5px;
    }

    .principleInput {
        height: 100%;
        width: 100%;
    }
}

.quarryContainer {
    .quarryEmptyLine {
        display: flex;
        width: 165px;
        height: 25px;
        border-bottom: 1px solid $lineColor;

        .emptyLineInput {
            height: 20px;
            width: 252px;
        }
    }
}
