

.baseContainer{
    position: relative;

    .linkingContainer{
        display: flex;
        margin-top: 10px;
        flex-direction: column;
    
       
    }
    
    .overlay{
        position: absolute;
        top: 0px;
        left:0px;
        width: 100%;
        height: 100%;
        background-color: red;
    }
}