@import '../../sheetBase.scss';

.pageContainer {
    @include borders(1px);
    background-color: white;
    width: 900px;
    height: 1153px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway;

    .innerContainer {
        position: relative;
        @include borders(2px);
        width: calc(100% - $innerRight - $innerLeft);
        height: calc(100% - $innerTop - $innerBottom);
        margin: $innerTop $innerRight $innerBottom $innerLeft;
        padding: 25px;

        input {
            background-color: transparent;
            border: none;
            font-size: 14px;
        }

        p {
            margin: 0px;
        }
    }
}

.dottedLine {
    border-bottom: 1px dotted #000;
    width: 100%;
    margin-top: 20px;
}

.checkboxContainer {
    position: relative;
    cursor: pointer;
    width: 11px;
    height: 11px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ span {
            box-shadow: inset 0px 0px 0px 30px #000;
        }

        &:checked ~ span:after {
            display: block;
        }
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        border: 1px solid black;
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }

    &:hover input ~ span {
        background-color: #ccc;
    }
}

.boldCheckBox {
    outline: solid 3px black;
    outline-offset: -3px;
}
