.generalContainer {
    font-family: Raleway;
    width: 243px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    row-gap: 6px;
    position: relative;
    background-color: black;
    padding: 10px;
    padding-right: 20px;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    p {
        margin: 0px;
        padding: 0px;
    }
    .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        p {
            font-size: 18px;
            line-height: 18px;

            height: 18px;

            &.year {
                font-size: 48px;
                line-height: 56px;
                height: 48px;
            }
        }
    }

    .epochSeparator {
        height: 1px;
        background-color: white;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .epochList {
        height: 382px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;

        .title {
            font-weight: bold;
            font-size: 16px;
        }
        .epochListInner {
            overflow-y: auto;
            width: 100%;

            /* width */
            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .epochInnerContainer {
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                align-items: flex-start;

                button{
                    width: calc(100% - 5px);
                    background-color: transparent;
                    border: none;
                    margin: 0px;
                    padding: 0px;
                    text-align: left;

                   
                }
            }
        }
    }

    .lanternYearChange {
        display: flex;
        flex-direction: column;
        text-align: left;
        row-gap: 10px;

        p{
            font-size: 14px;
        }

        .yearChangeContainer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            select {
                width: 50px;
                background-color: white;

                color : black;

                option{
                    color:black;
                }
            }

            button {
                background-color: transparent;
                border: 1px white solid;
                width: 60px;
                text-align: center;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.103);
                }

                &:disabled {
                    color: gray;
                    border-color: gray;
                    pointer-events: none;

                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .navButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input[type='button']{
            

            background-color: transparent;
            border: 1px white solid;
            width: 60px;
            text-align: center;
            width: 100px;

            &:hover{
                background-color: rgba(255, 255, 255, 0.103);
            }

            &:disabled {
                color: gray;
                border-color: gray;
                pointer-events: none;

                &:hover{
                    background-color: transparent;
                }
            }
        }
    }
}
