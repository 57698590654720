#settlement_pagination {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settlement_pagination a, .settlement_pagination span {
  color: white;
  float: left;  
  text-decoration: none;  
  margin: 0px 3px 0px 3px;
}

.settlement_pagination a.active {  
  color: cyan;
}