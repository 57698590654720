@import '../styles/base-styles.scss';

.lostSettlementsContainer {
    width: 253px;
    .lostSettlementsInnerContainer {
        margin-top: 8px;
        display: flex;
        gap: 2.8px;

        .checkboxContainer {
            width: 10px;
            height: 10px;
        }
    }

    .horizontalLine {
        @include horizontalLine(1px, 253px, $lineColor);
    }
}
