
// @media print {
//     @page {
//         size: portrait;
//       }
//       body * {
//         visibility: hidden;
//       }
//       .print-only, .print-only * {
//         visibility: visible;
//       }
//       .print-only {
//         position: absolute;
//         left: 0;
//         top: 0;

//         margin: 0;
//         transform-origin: center;
//         transform: scale(0.9) translateX(-7%) translateY(-2%);
//       }
// }

.settlement-directory-container {
   

    .hide-page {
        display: none;
    }

    .show-page {
        display: block;
    }

    @media print {
        .print_sheet {
            display: block !important;
        }
    }

    .check-container {
        display: block;
        cursor: pointer;
    }

    .check-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        border: 1px solid black;
    }

    .check-container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .check-container input:checked ~ .checkmark {
        background-color: #000;
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .check-container input:checked ~ .checkmark:after {
        display: block;
    }

    .check-container.bold {
        outline: solid 3px black;
        outline-offset: -3px;
    }

    #settlement-list {
        padding-top: 40px;
        width: 200px;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.8);
    }

    select.settlements_list {
        width: inherit;
        background-color: inherit;
        box-shadow: inherit;
        color: white;
        border-width: 0;
        padding: 0.4em;
        scrollbar-width: none;
        overflow-y: hidden;
        overflow-x: auto;
    }

    select.settlements_list:focus {
        outline: none !important;
        border: none;
    }

    .record_sheet_front {
        width: 900px;
        height: 1153px;
        float: left;
        text-align: left;
        position: relative;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.5);
    }

    .record_sheet_front > input[type='text'],
    .record_sheet_back > input[type='text'],
    .survivor_sheet > input[type='text'] {
        background: none;
        border: none;
    }

    .record_sheet_back {
        background-repeat: no-repeat;
        background-size: contain;
        width: 900px;
        height: 1153px;
        float: left;
        text-align: left;
        position: relative;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.5);
    }

    .survivor_name {
        float: left;
        width: 196px;
        height: inherit;
        overflow: hidden;
        margin-left: 2px;
        text-decoration: inherit;
        text-decoration-color: inherit;
    }

    .survivor_gender {
        float: left;
        width: 48px;
        text-align: center;
        height: inherit;
        text-decoration: inherit;
        text-decoration-color: inherit;
    }
    .survivor_sheet {
        &.ark{
            width: auto !important;
        }
        font-family: Raleway;
        background-image: url('../images/survivor-sheet.png') !important;
        background-repeat: no-repeat;
        background-size: contain;
        width: 640px;
        height: 640px;
        float: left;
        text-align: left;
        position: relative;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.5);

        #survivor-data {
            display: block;
            width: 1120px;
        }

        // #survivor_name {
        //     top: 22px;
        //     left: 65px;
        //     width: 192px;
        // }

        // #lifetime_entry {
        //     top: 597px;
        // }

        .misc_check {
            width: 10px;
            height: 10px;
        }

        .misc_check.reroll {
            top: 583px;
            left: 558px;
        }

        .misc_check.hunt {
            top: 484px;
            left: 537px;
        }

        .misc_check.arts {
            top: 331px;
            left: 502px;
        }

        .survivor_line {
            left: 334px;
            width: 280px;
            height: 16px;
            font-size: 0.7em;
        }

        #ability_0 {
            top: 494px;
        }

        #ability_1 {
            top: 511px;
        }

        #ability_2 {
            top: 529px;
        }

        #ability_3 {
            top: 546px;
        }

        #disorders_0 {
            top: 417px;
        }

        #disorders_1 {
            top: 434px;
        }

        #disorders_2 {
            top: 452px;
        }

        #fighting_art_0 {
            top: 341px;
        }

        #fighting_art_1 {
            top: 358px;
        }

        #fighting_art_2 {
            top: 376px;
        }

        .armor_toggle {
            left: 270px;
            width: 15px;
            height: 15px;
        }

        .armor_toggle.heavy {
            left: 290px !important;
        }

        .armor_toggle.heavy > .checkmark {
            border: 4px solid #000 !important;
        }

        .legs_toggle {
            top: 569px;
        }

        .waist_toggle {
            top: 510px;
        }

        .body_toggle {
            top: 448px;
        }

        .arms_toggle {
            top: 387px;
        }

        .head_toggle {
            top: 327px;
        }

        .armor_count {
            left: 28px;
            width: 32px;
            height: 18px;
            border: none;
            font-size: 0.8em;
            background: none;
        }

        #legs_count {
            top: 571px;
        }

        #waist_count {
            top: 510px;
        }

        #body_count {
            top: 448px;
        }

        #arms_count {
            top: 388px;
        }

        #head_count {
            top: 329px;
        }

        .courage_xp,
        .understanding_xp {
            top: 187px;
            width: 10px;
            height: 10px;
        }

        .courage_xp_0 {
            left: 346px;
        }

        .courage_xp_1 {
            left: 359px;
        }

        .courage_xp_2 {
            left: 372px;
        }

        .courage_xp_3 {
            left: 385px;
        }

        .courage_xp_4 {
            left: 398px;
        }

        .courage_xp_5 {
            left: 411px;
        }

        .courage_xp_6 {
            left: 424px;
        }

        .courage_xp_7 {
            left: 438px;
        }

        .courage_xp_8 {
            left: 451px;
        }

        .understanding_xp_0 {
            left: 486px;
        }

        .understanding_xp_1 {
            left: 500px;
        }

        .understanding_xp_2 {
            left: 513px;
        }

        .understanding_xp_3 {
            left: 526px;
        }

        .understanding_xp_4 {
            left: 540px;
        }

        .understanding_xp_5 {
            left: 552px;
        }

        .understanding_xp_6 {
            left: 565px;
        }

        .understanding_xp_7 {
            left: 579px;
        }

        .understanding_xp_8 {
            left: 592px;
        }

        .brain_check {
            top: 252px;
            left: 277px;
            width: 16px;
            height: 16px;
        }

        // #insanity_count {
        //     top: 251px;
        //     left: 40px;
        //     width: 30px;
        //     border: none;
        //     font-size: 0.8em;
        //     background: none;
        //     text-align: center;
        // }

        // #movement {
        //     top: 167px;
        //     left: 31px;
        //     width: 42px;
        //     height: 42px;
        //     text-align: center;
        //     border: none;
        // }

        .attribute_count {
            top: 166px;
            width: 32px;
            height: 32px;
            text-align: center;
            font-size: 0.65em;
        }

        #accuracy_count {
            left: 84px;
        }

        #strength_count {
            left: 130px;
        }

        #evasion_count {
            left: 175px;
        }

        #luck_count {
            left: 221px;
        }

        #speed_count {
            left: 267px;
        }

        // #survival_count {
        //     top: 92px;
        //     left: 37px;
        //     width: 42px;
        //     height: 42px;
        //     text-align: center;
        // }

        .survival_check {
            top: 123px;
            left: 85px;
            width: 10px;
            height: 10px;
        }

        .survivor_checks {
            left: 244px;
            width: 7px;
            height: 7px;
        }

        .survivor_checks.encourage {
            top: 100px;
        }

        .survivor_checks.surge {
            top: 109px;
        }

        .survivor_checks.dash {
            top: 119px;
        }

        .survivor_checks.endure {
            top: 129px;
        }

        // #weapon_proficiency {
        //     top: 107px;
        //     left: 373px;
        //     width: 108px;
        //     height: 16px;
        //     font-size: 0.7em;
        // }

        .weapon_xp {
            top: 95px;
            width: 10px;
            height: 10px;
        }

        .weapon_xp_0 {
            left: 498px;
        }

        .weapon_xp_1 {
            left: 511px;
        }

        .weapon_xp_2 {
            left: 525px;
        }

        .weapon_xp_3 {
            left: 539px;
        }

        .weapon_xp_4 {
            left: 553px;
        }

        .weapon_xp_5 {
            left: 566px;
        }

        .weapon_xp_6 {
            left: 580px;
        }

        .weapon_xp_7 {
            left: 594px;
        }

        .hunt_xp {
            top: 27px;
            width: 10px;
            height: 10px;
        }

        .hunt_xp_0 {
            left: 379px;
        }

        .hunt_xp_1 {
            left: 394px;
        }

        .hunt_xp_2 {
            left: 408px;
        }

        .hunt_xp_3 {
            left: 422px;
        }

        .hunt_xp_4 {
            left: 436px;
        }

        .hunt_xp_5 {
            left: 451px;
        }

        .hunt_xp_6 {
            left: 465px;
        }

        .hunt_xp_7 {
            left: 479px;
        }

        .hunt_xp_8 {
            left: 494px;
        }

        .hunt_xp_9 {
            left: 508px;
        }

        .hunt_xp_10 {
            left: 522px;
        }

        .hunt_xp_11 {
            left: 537px;
        }

        .hunt_xp_12 {
            left: 551px;
        }

        .hunt_xp_13 {
            left: 566px;
        }

        .hunt_xp_14 {
            left: 579px;
        }

        .hunt_xp_15 {
            left: 594px;
        }

        .gender_check {
            top: 28px;
            width: 10px;
            height: 10px;
        }

        .gender_check.male {
            left: 273px;
        }

        .gender_check.female {
            left: 296px;
        }

        #stalwart_position {
            top: 232px;
            left: 344px;
        }

        #prepared_position {
            top: 256px;
            left: 344px;
        }

        #match_position {
            top: 280px;
            left: 344px;
        }

        #analyze_position {
            top: 232px;
            left: 484px;
        }

        #explore_position {
            top: 256px;
            left: 484px;
        }

        #tinker_position {
            top: 280px;
            left: 484px;
        }
    }

    #home_button {
        height: 26px;
        width: 26px;
        background-image: url('../images/home.png');
        background-size: contain;
        background-color: transparent;
        border: 0;
    }

    #home_button:focus {
        outline: none !important;
        border: none;
    }

    .toolbar {
        background-color: gray;
        height: 30px;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.6);

    }

    .toolbar_left_side {
        width: 300px;
    }

    .toolbar_divider {
        border-left: 1px solid #fff;
        height: 22px;
        margin: 2px 0px 2px 2px;
    }

    .toolbar_divider.left {
        float: left;
    }

    .toolbar_divider.right {
        float: right;
    }

    	
    .header-left {
        margin-left: 2px;
        display: flex;
        gap: 2px;
        align-items: center;
        width: max-content;
    }

    .header-right {
        margin-right: 2px;
        display: flex;
        gap: 2px;
        align-items: center;
        width: max-content;
        margin-left: auto;
    }

    .print_button {
        position: relative;
        top: 1px;
    }

    .flip_button {
        height: 26px;
        width: 26px;
        background-image: url('../images/flip.png');
        background-size: contain;
        background-color: transparent;
        border: 0;
    }
    .sheet_dropdown {
        font-size: 13px;
        color: white;
        border: none;
        background: transparent;

        option{

            background-color: rgb(0 0 0 / 60%);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
        }
    }

    .flip_button:focus {
        outline: none !important;
        border: none;
    }

    .info_button {
        float: right;
        height: 26px;
        width: 26px;
        margin-right: 4px;
        background-image: url('../images/info.png');
        background-size: contain;
        background-color: transparent;
        border: 0;
    }

    .info_button:focus {
        outline: none !important;
        border: none;
    }

    .nav_buttons {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-size: contain;
        background-color: transparent;
        border: 0;
        background-repeat: no-repeat;
    }

    .nav_buttons:focus {
        outline: none !important;
        border: none;
    }

    .save_button {
        background-image: url('../images/save.png');
    }

    .nav_buttons:disabled {
        opacity: 0.5;
    }

    .refresh_button {
        background-image: url('../images/refresh.png');
    }

    .delete_button {
        background-image: url('../images/delete.png');
    }

    .copy_button {
        background-image: url('../images/copy.png');
    }

    .paste_button {
        background-image: url('../images/paste.png');
    }

    .mile {
        width: 10px;
        height: 10px;
    }

    .mile.top {
        top: 637px;
    }

    .mile.bottom {
        top: 661px;
    }

    .mile_0 {
        left: 70px;
    }

    .mile_1 {
        left: 70px;
    }

    .mile_2 {
        left: 337px;
    }

    .mile_3 {
        left: 337px;
    }

    .mile_4 {
        left: 582px;
    }

    .death {
        width: 11px;
        height: 11px;
    }

    .death.up {
        top: 103px;
    }

    .death.down {
        top: 116px;
    }

    .death_0 {
        left: 577px;
    }

    .death_1 {
        left: 590px;
    }

    .death_2 {
        left: 604px;
    }

    .death_3 {
        left: 617px;
    }

    .death_4 {
        left: 630px;
    }

    .death_5 {
        left: 644px;
    }

    .death_6 {
        left: 657px;
    }

    .death_7 {
        left: 670px;
    }

    .death_8 {
        left: 684px;
    }

    .death_9 {
        left: 697px;
    }

    .death_10 {
        left: 711px;
    }

    .death_11 {
        left: 725px;
    }

    .death_12 {
        left: 738px;
    }

    .death_13 {
        left: 752px;
    }

    .death_14 {
        left: 765px;
    }

    .death_15 {
        left: 779px;
    }

    .death_16 {
        left: 792px;
    }

    .death_17 {
        left: 805px;
    }

    .death_18 {
        left: 819px;
    }

    .death_19 {
        left: 577px;
    }

    .death_20 {
        left: 590px;
    }

    .death_21 {
        left: 604px;
    }

    .death_22 {
        left: 617px;
    }

    .death_23 {
        left: 630px;
    }

    .death_24 {
        left: 644px;
    }

    .death_25 {
        left: 657px;
    }

    .death_26 {
        left: 670px;
    }

    .death_27 {
        left: 684px;
    }

    .death_28 {
        left: 697px;
    }

    .death_29 {
        left: 711px;
    }

    .death_30 {
        left: 725px;
    }

    .death_31 {
        left: 738px;
    }

    .death_32 {
        left: 752px;
    }

    .death_33 {
        left: 765px;
    }

    .death_34 {
        left: 779px;
    }

    .death_35 {
        left: 792px;
    }

    .death_36 {
        left: 805px;
    }

    .death_37 {
        left: 819px;
    }

    .time_check {
        width: 12px;
        height: 12px;
    }

    .time_check.left {
        left: 76px;
    }

    .time_check.right {
        left: 455px;
    }

    .time_check_0 {
        top: 170px;
    }

    .time_check_1 {
        top: 194px;
    }

    .time_check_2 {
        top: 218px;
    }

    .time_check_3 {
        top: 242px;
    }

    .time_check_4 {
        top: 266px;
    }

    .time_check_5 {
        top: 289px;
    }

    .time_check_6 {
        top: 313px;
    }

    .time_check_7 {
        top: 337px;
    }

    .time_check_8 {
        top: 360px;
    }

    .time_check_9 {
        top: 384px;
    }

    .time_check_10 {
        top: 408px;
    }

    .time_check_11 {
        top: 431px;
    }

    .time_check_12 {
        top: 455px;
    }

    .time_check_13 {
        top: 478px;
    }

    .time_check_14 {
        top: 502px;
    }

    .time_check_15 {
        top: 526px;
    }

    .time_check_16 {
        top: 550px;
    }

    .time_check_17 {
        top: 573px;
    }

    .time_check_18 {
        top: 170px;
    }

    .time_check_19 {
        top: 194px;
    }

    .time_check_20 {
        top: 218px;
    }

    .time_check_21 {
        top: 242px;
    }

    .time_check_22 {
        top: 266px;
    }

    .time_check_23 {
        top: 289px;
    }

    .time_check_24 {
        top: 313px;
    }

    .time_check_25 {
        top: 337px;
    }

    .time_check_26 {
        top: 360px;
    }

    .time_check_27 {
        top: 384px;
    }

    .time_check_28 {
        top: 408px;
    }

    .time_check_29 {
        top: 431px;
    }

    .time_check_30 {
        top: 455px;
    }

    .time_check_31 {
        top: 478px;
    }

    .time_check_32 {
        top: 502px;
    }

    .time_check_33 {
        top: 526px;
    }

    .time_check_34 {
        top: 550px;
    }

    .time_check_35 {
        top: 573px;
    }

    .innovation {
        width: 170px;
        height: 17px;
        font-size: 0.8em;
    }

    .innovation.left {
        left: 71px;
    }

    .innovation.right {
        left: 267px;
    }

    .innovation_0 {
        top: 752px;
    }

    .innovation_1 {
        top: 768px;
    }

    .innovation_2 {
        top: 784px;
    }

    .innovation_3 {
        top: 801px;
    }

    .innovation_4 {
        top: 818px;
    }

    .innovation_5 {
        top: 835px;
    }

    .innovation_6 {
        top: 852px;
    }

    .innovation_7 {
        top: 869px;
    }

    .innovation_8 {
        top: 886px;
    }

    .innovation_9 {
        top: 902px;
    }

    .innovation_10 {
        top: 736px;
    }

    .innovation_11 {
        top: 752px;
    }

    .innovation_12 {
        top: 768px;
    }

    .innovation_13 {
        top: 784px;
    }

    .innovation_14 {
        top: 801px;
    }

    .innovation_15 {
        top: 818px;
    }

    .innovation_16 {
        top: 835px;
    }

    .innovation_17 {
        top: 852px;
    }

    .innovation_18 {
        top: 869px;
    }

    .innovation_19 {
        top: 886px;
    }

    .innovation_20 {
        top: 902px;
    }

    .settlement_check {
        width: 8px;
        height: 8px;
    }

    .settlement_check.left {
        left: 462px;
    }

    .settlement_check.right {
        left: 658px;
    }

    .settlement_check_0 {
        top: 757px;
    }

    .settlement_check_1 {
        top: 772px;
    }

    .settlement_check_2 {
        top: 788px;
    }

    .settlement_check_3 {
        top: 805px;
    }

    .settlement_check_4 {
        top: 822px;
    }

    .settlement_check_5 {
        top: 839px;
    }

    .settlement_check_6 {
        top: 856px;
    }

    .settlement_check_7 {
        top: 873px;
    }

    .settlement_check_8 {
        top: 741px;
    }

    .settlement_check_9 {
        top: 756px;
    }

    .settlement_check_10 {
        top: 773px;
    }

    .settlement {
        left: 658px;
        width: 172px;
        height: 16px;
        font-size: 0.8em;
    }

    #settlement_0 {
        top: 785px;
    }

    #settlement_1 {
        top: 803px;
    }

    #settlement_2 {
        top: 820px;
    }

    #settlement_3 {
        top: 837px;
    }

    #settlement_4 {
        top: 854px;
    }

    #settlement_5 {
        top: 871px;
    }

    .principle_check {
        width: 8px;
        height: 8px;
    }

    .principle_check.left {
        left: 239.4px;
    }

    .principle_check.right {
        left: 346px;
    }

    .principle_check_0 {
        top: 975px;
    }

    .principle_check_1 {
        top: 975px;
    }

    .principle_check_2 {
        top: 992px;
    }

    .principle_check_3 {
        top: 992px;
    }

    .principle_check_4 {
        top: 1009px;
    }

    .principle_check_5 {
        top: 1009px;
    }

    .principle_check_6 {
        top: 1026px;
    }

    .principle_check_7 {
        top: 1026px;
    }

    .principle {
        left: 71px;
        width: 366px;
        height: 16px;
        font-size: 0.8em;
    }

    #principle_0 {
        top: 1041px;
    }

    #principle_1 {
        top: 1058px;
    }

    .quarry_check {
        left: 474px;
        width: 11px;
        height: 11px;
    }

    .quarry_check.top {
        top: 994px;
    }

    .quarry_check.bottom {
        top: 1016px;
    }

    .quarry {
        width: 159px;
        height: 22px;
        font-size: 0.8em;
    }

    .quarry.left {
        left: 474px;
    }

    .quarry.right {
        left: 657px;
    }

    .quarry_0 {
        top: 1036px;
    }

    .quarry_1 {
        top: 968px;
    }

    .quarry_2 {
        top: 990px;
    }

    .quarry_3 {
        top: 1013px;
    }

    .quarry_4 {
        top: 1036px;
    }

    .resource,
    .gear {
        width: 170px;
        height: 17px;
        font-size: 0.8em;
    }

    .resource.left {
        left: 71px;
    }

    .resource.right {
        left: 266px;
    }

    #resource_0 {
        top: 111px;
    }

    #resource_1 {
        top: 128px;
    }

    #resource_2 {
        top: 145px;
    }

    #resource_3 {
        top: 161px;
    }

    #resource_4 {
        top: 178px;
    }

    #resource_5 {
        top: 195px;
    }

    #resource_6 {
        top: 212px;
    }

    #resource_7 {
        top: 229px;
    }

    #resource_8 {
        top: 246px;
    }

    #resource_9 {
        top: 263px;
    }

    #resource_10 {
        top: 280px;
    }

    #resource_11 {
        top: 298px;
    }

    #resource_12 {
        top: 319px;
    }

    #resource_13 {
        top: 111px;
    }

    #resource_14 {
        top: 128px;
    }

    #resource_15 {
        top: 145px;
    }

    #resource_16 {
        top: 161px;
    }

    #resource_17 {
        top: 178px;
    }

    #resource_18 {
        top: 195px;
    }

    #resource_19 {
        top: 212px;
    }

    #resource_20 {
        top: 229px;
    }

    #resource_21 {
        top: 246px;
    }

    #resource_22 {
        top: 263px;
    }

    #resource_23 {
        top: 280px;
    }

    #resource_24 {
        top: 298px;
    }

    #resource_25 {
        top: 319px;
    }

    .gear.left {
        left: 463px;
    }

    .gear.right {
        left: 659px;
    }

    #gear_0 {
        top: 111px;
    }

    #gear_1 {
        top: 128px;
    }

    #gear_2 {
        top: 145px;
    }

    #gear_3 {
        top: 161px;
    }

    #gear_4 {
        top: 178px;
    }

    #gear_5 {
        top: 195px;
    }

    #gear_6 {
        top: 212px;
    }

    #gear_7 {
        top: 229px;
    }

    #gear_8 {
        top: 246px;
    }

    #gear_9 {
        top: 263px;
    }

    #gear_10 {
        top: 280px;
    }

    #gear_11 {
        top: 298px;
    }

    #gear_12 {
        top: 319px;
    }

    #gear_13 {
        top: 111px;
    }

    #gear_14 {
        top: 128px;
    }

    #gear_15 {
        top: 145px;
    }

    #gear_16 {
        top: 161px;
    }

    #gear_17 {
        top: 178px;
    }

    #gear_18 {
        top: 195px;
    }

    #gear_19 {
        top: 212px;
    }

    #gear_20 {
        top: 229px;
    }

    #gear_21 {
        top: 246px;
    }

    #gear_22 {
        top: 263px;
    }

    #gear_23 {
        top: 280px;
    }

    #gear_24 {
        top: 298px;
    }

    .nemesis_check.small {
        width: 9px;
        height: 9px;
    }

    .nemesis_check.large {
        left: 83px;
        width: 10px;
        height: 10px;
    }

    .nemesis_check.small.left {
        left: 205px;
    }

    .nemesis_check.small.center {
        left: 243px;
    }

    .nemesis_check.small.right {
        left: 284px;
    }

    .nemesis_check.small.top {
        top: 411px;
    }

    .nemesis_check.small.middle {
        top: 435px;
    }

    .nemesis_check.small.bottom {
        top: 458px;
    }

    .nemesis_check.large.top {
        top: 435px;
    }

    .nemesis_check.large.bottom {
        top: 457px;
    }

    .nemesis {
        left: 84px;
        width: 226px;
        height: 22px;
        font-size: 0.8em;
    }

    #nemesis_0 {
        top: 120px;
        left: 10px;
    }

    #nemesis_1 {
        top: 144px;
        left: 10px;
    }

    // #research {
    //     top: 367px;
    //     left: 504px;
    //     width: 40px;
    //     height: 40px;
    //     text-align: center;
    // }

    .volume {
        left: 357px;
        width: 187px;
        height: 17px;
        font-size: 0.7em;
    }

    #volume_0 {
        top: 443px;
    }

    #volume_1 {
        top: 466px;
    }

    #volume_2 {
        top: 488px;
    }

    #volume_3 {
        top: 512px;
    }

    .note {
        left: 576px;
        width: 253px;
        height: 17px;
        font-size: 0.7em;
    }

    #note_0 {
        top: 367px;
    }

    #note_1 {
        top: 382px;
    }

    #note_2 {
        top: 399px;
    }

    #note_3 {
        top: 416px;
    }

    #note_4 {
        top: 432px;
    }

    #note_5 {
        top: 448px;
    }

    #note_6 {
        top: 464px;
    }

    #note_7 {
        top: 481px;
    }

    #note_8 {
        top: 497px;
    }

    #note_9 {
        top: 512px;
    }

    #note_10 {
        top: 529px;
    }

    // #population {
    //     top: 565px;
    //     left: 70px;
    //     width: 40px;
    //     height: 40px;
    //     text-align: center;
    // }

    .set_check {
        top: 594.4px;
        width: 10px;
        height: 10px;
    }

    .set_check_0 {
        left: 577px;
    }

    .set_check_1 {
        left: 590px;
    }

    .set_check_2 {
        left: 603px;
    }

    .set_check_3 {
        left: 615px;
    }

    .set_check_4 {
        left: 628px;
    }

    .set_check_5 {
        left: 640px;
    }

    .set_check_6 {
        left: 653px;
    }

    .set_check_7 {
        left: 666px;
    }

    .set_check_8 {
        left: 679px;
    }

    .set_check_9 {
        left: 691px;
    }

    .set_check_10 {
        left: 704px;
    }

    .set_check_11 {
        left: 716px;
    }

    .set_check_12 {
        left: 729px;
    }

    .set_check_13 {
        left: 742px;
    }

    .set_check_14 {
        left: 754px;
    }

    .set_check_15 {
        left: 767px;
    }

    .set_check_16 {
        left: 780px;
    }

    .set_check_17 {
        left: 792px;
    }

    .set_check_18 {
        left: 805px;
    }

    .set_check_19 {
        left: 817px;
    }

    #create_settlement_name {
        margin-top: 2px;
        margin-bottom: 12px;
        float: left;
        width: 168px;
        margin-left: 2px;
    }

    #confirm_settlement_creation {
        height: 26px;
        margin-top: 2px;
        margin-left: 4px;
        width: 26px;
        float: left;
        background-image: url('../images/add.png');
        background-size: contain;
    }
    .survivor_gear {
        width: 220px;
        display: inline-block;
    }

    .survivor_gear label {
        float: left;
        color: white;
        width: 65px;
        text-align: left;
    }

    .survivor_gear input {
        float: left;
        width: 152px;
    }

    #survivor_notes_field {
        width: 220px;
        display: inline-block;
    }

    #survivor_notes_field label {
        float: left;
        color: white;
        width: 65px;
        text-align: left;
    }

    #survivor_notes_field textarea {
        float: left;
        width: 152px;
        height: 75px;
        resize: none;
    }

    #survivor_dead_field {
        width: 220px;
        display: inline-block;
    }

    #survivor_dead_field label {
        float: left;
        color: white;
        width: 65px;
        text-align: left;
    }

    #survivor_dead_field input {
        float: left;
    }

    #survivor_year_field {
        width: 220px;
        display: inline-block;
    }

    #survivor_year_field label {
        float: left;
        color: white;
        width: 65px;
        text-align: left;
    }

    #survivor_year_field select {
        float: left;
    }

    .survivor_notes {
        float: left;
        width: 514px;
        height: inherit;
        overflow: hidden;
        text-decoration: inherit;
        text-decoration-color: inherit;
        padding-left: 3px;
    }

    #survivor_pagination {
        top: 1044px;
        left: 70px;
        width: 760px;
        height: 50px;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
    }

    .pagination a,
    .pagination span {
        color: black;
        float: left;
        padding: 0px 8px;
        text-decoration: none;
        border: 1px solid #ddd; /* Gray */
    }

    .pagination a:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .pagination a:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .pagination a.active {
        background-color: #33beff;
        color: white;
    }

    .pagination a:hover:not(.active) {
        background-color: #ddd;
    }

    #overlay {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: black;
        -moz-opacity: 0.8;
        filter: alpha(opacity=80);
        opacity: 0.8;
    }

    #survivor_info {
        font-family: Raleway;
        width: 243px;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.6);
        padding: 10px;
        height: fit-content;
    }

    #settlement_info {
        width: 900px;
        float: left;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.6);
        padding: 5px 0px;
    }

    .core_record_sheet_back {
        position: relative;
        #survivor-list-filters {
            position: absolute;
            top: 612px;
            left: 603px;
            display: flex;
        }

        #survivor-list-filters label {
            margin: 0px 4px 0px 4px;
        }

        #survivor-list-filters select {
            width: 40px;
            margin: -2px 0px 4px 0px;
            -webkit-appearance: none;
            background-image: url('../images/arrow.png');
            background-size: contain;
        }

        #survivor-list-filters button {
            margin: -3px 0px 3px 6px;
        }

        #create_survivor {
            position: absolute;
            top: 1068px;
            left: 663px;
            width: 182px;
        }

        #create_survivor_name {
            margin-top: 2px;
            margin-bottom: 12px;
            float: left;
            width: 140px;
            margin-left: 2px;
            height: 22px;
            font-size: 0.8em;
        }

        #confirm_survivor_creation {
            height: 26px;
            margin-top: 2px;
            margin-left: 4px;
            width: 22px;
            height: 22px;
            float: left;
            background-image: url('../images/add.png');
            background-size: contain;
        }

        #survivor_list {
            top: 646px;
            left: 51.1px;
            width: 776.9px;
            height: 407px;
        }

        .survivor_row {
            width: inherit;
            height: 16.9px;
        }

        .survivor_row.dead {
            text-decoration: line-through;
            text-decoration-color: red;
        }

        .survivor_row:hover {
            background-color: #ddd;
        }

        .view_survivor_button {
            width: 16.9px;
            height: inherit;
            float: left;
            background-image: url('../images/eye.png');
            background-size: contain;
            border: 0;
        }

        .view_survivor_button:focus {
            outline: none !important;
            border: none;
        }
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
        cursor: pointer;
        height: 12px;
        left: 0;
        position: absolute;
        top: 0;
        width: 12px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 4px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotate(-45deg);
        width: 7px;
    }

    .round input[type='checkbox'] {
        visibility: hidden;
    }

    .round input[type='checkbox']:checked + label {
        background-color: #000;
        border-color: #000;
    }

    .round input[type='checkbox']:checked + label:after {
        opacity: 1;
    }

    .survivor-filtered-pagination {
        display: inline-flex;
        justify-content: center;
        margin-top: 15px;
        width: 100%;
    }

    .previous-survivor {
        width: 100px;
    }

    .next-survivor {
        width: 100px;
        margin-left: 36px;
    }

    #create_settlement,
    #settlement-list,
    #record_sheet_front,
    #record_sheet_back,
    #settlement_info {
        font-family: Raleway;
    }

    .expansion_entry {
        float: left;
        margin-right: 15px;
    }

    #expansion_ids {
        display: inline-flex;
        width: 750px;
        flex-wrap: wrap;
    }

    #expansions_field {
        width: 880px;
        display: inline-block;

        label {
            float: left;
            color: #fff;
            padding-bottom: 5px;
            margin-top: -3px;
            text-align: left;
        }

        input {
            float: left;
            margin-right: 10px;
        }
    }
    #description_field {
        width: 880px;
        display: inline-block;
    }

    #description_field label {
        float: left;
        color: white;
        width: 100px;
        text-align: left;
    }

    #description_field textarea {
        float: left;
        width: 770px;
        height: 96px;
        resize: none;
    }

    #expansions_field {
        width: 880px;
        display: inline-block;
    }

    #expansions_field label {
        float: left;
        color: white;
        padding-bottom: 5px;
        margin-top: -3px;
        text-align: left;
    }

    #expansions_field input {
        float: left;
        margin-right: 10px;
    }

    #survivor_info_top {
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.6);
        padding: 5px 0px;
        margin-bottom: 10px;
        float: none;
        width: auto;

        span {
            color: #fff;
            line-height: 32px;
        }

        .dead-check {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: text-bottom;
        }

        .info-label {
            display: inline-block;

            text-align: left;
            width: 56px;
        }

        .info_row {
            margin: 8px;
            margin-left: 32px;
            width: 100%;
            height: 32px;
        }

        .dead_field,
        .year_field {
            float: left;
            height: 32px;
        }

        .gear_entry_row {
            margin: 8px;
            margin-left: 32px;
            width: 100%;
            height: 32px;

            .gear_entry_column {
                width: 33.33%;
                display: inline-block;
                label,
                span {
                    float: left;
                    color: #fff;
                    text-align: left;
                    margin-right: 10px;
                    line-height: 32px;
                }

                input {
                    float: left;
                    margin-right: 10px;
                    height: 100%;
                    height: 32px;
                }
            }
        }

        #notes_field {
            margin: 8px;
            margin-left: 32px;

            label {
                float: left;
                color: #fff;
                text-align: left;
                margin-right: 10px;
                line-height: 32px;
            }

            #notes {
                width: 844px;
            }
        }
    }
}

@media print {
    #survivor-list-filters,
    #survivor_info,
    #survivor_pagination,
    #create_survivor {
        display: none;
    }

    .gearGrid {
        margin-left: -100px;
    }
}
