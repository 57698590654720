@import '../styles/base-styles.scss';

.innovationsContainer {
    .innovationsHeader {
        display: flex;
        flex-direction: column;
        margin: 10px 0 5px;

        .innovationsHeaderTitle {
            @include silveradoFont;
            font-size: 16.8px;
            margin: 0;
        }
        .innovationsHelper {
            @include amplitudeWideBook;
            font-size: 8px;
            margin: 0;
            width: 240px;
        }
    }

    .innovationsInnerContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(11, auto); 
        margin-bottom: 10px;
        grid-auto-flow: column;
    }

    .innovationsHorizontalLine {
        @include horizontalLine(1px, 170px, $lineColor);
        height: 17px;
        display: flex;
        align-items: center;

        .innovationInput {
            height: 100%;
            width: 100%;
        }
    }

    .firstLineLabel {
        @include amplitudeWideBook;
        font-size: 10px;
        position: relative;
        bottom: 2px;
    }
}
