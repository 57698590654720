@import '../styles/base-styles.scss';

.paragraphContainer {
    .innerContainer {
        display: grid;
        grid-auto-flow: column;

        .info {
            @include amplitudeWideBook;
            font-size: 8px;
            position: relative;
            top: 6px;
        }

        .horizontalLine {
            width: 100%;
            display: flex;
            align-items: center;
            @include horizontalLine(1px, 100%, $lineColor);

            .input {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.notes {
        .horizontalLine {
            height: 16px;
        }
    }
}
