@import '../styles/base-styles.scss';

.nemesisMonstersContainer {
    @include borders(1px);
    height: 190px;
    width: 254px;
    padding: 10px;
    position: relative;

    .nemesisMonsterInput {
        position: absolute;
        left: 84px;
        width: 226px;
        height: 22px;
        font-size: 0.8em;
    }

    .horizontalLine {
        position: absolute;
        @include horizontalLine(1px, 230px, $lineColor);
    }

    .nemesisCheckboxContainer {
        width: 8px;
        height: 8px;
    }

    .nemesisInnerContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .nemesisMonsterRow {
        width: 230px;
        height: 23px;
        position: absolute;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .nemesisMonster {
            @include amplitudeWideBook;
            font-size: 8px;
            width: 120px;
            position: relative;
            left: 12px;
        }

        .nemesisLvl {
            @include amplitudeWideBook;
            position: absolute;
            font-size: 8px;
        }
    }

    &.ark {
        @include borders(2px);
        height: 200px;
        width: 260px;
        margin-top: 10px;
    }

    .nemesisCheckboxBlock {
        position: absolute;
        margin-top: 2px;
        display: flex;
        flex-direction: column;

        .nemesisCheckboxRow {
            width: 230px;
            height: 23px;

            display: flex;
            align-items: center;

            .nemesisFirstCheckboxContainer {
                width: 125px;
                margin-top: 7px;
                height: 18px;
                display: flex;
                align-items: center;
            }

            .nemesisRestCheckboxContainer {
                display:flex;
                flex-direction: row;
                column-gap: 30px;
                margin-top: 7px;
                height: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
}
