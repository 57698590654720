@import "../../../customer-cp.module.scss";

.container {
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        @include phone {
            flex-direction: column;
        }
    }

    .linkAccountTitle {
        font-size: 14px;
    }

    $shopify-base-color: #95bf47;
    $shopify-dark-color: #64943e;

    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
    }

    .shopifyIcon {
        background-image: url(../../../../../assets/icons/shopify_blank.svg);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        margin: 2px;
    }

    .shopifyButton {
        appearance: none;
        background-color: $shopify-base-color;
        border: 1px solid $shopify-dark-color;
        border-radius: 6px;
        box-shadow: $shopify-dark-color 0 1px 0;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 6px 16px;
        position: relative;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: middle;
        white-space: nowrap;

        &:focus:not(:focus-visible):not(.focus-visible) {
            box-shadow: none;
            outline: none;
        }

        &:hover {
            background-color: $shopify-dark-color;
        }

        &:focus {
            box-shadow: $shopify-dark-color 0 0 0 3px;
            outline: none;
        }

        &:disabled {
            background-color: #94d3a2;
            border-color: rgba(27, 31, 35, 0.1);
            color: rgba(255, 255, 255, 0.8);
            cursor: default;
        }

        &:active {
            background-color: $shopify-base-color;
            box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
        }
    }

    .linkButton {
        background: none !important;
        border: none;
        padding: 0 !important;
        /*input has OS specific font-family*/
        color: #069;
        text-decoration: underline;
        cursor: pointer;
    }

    .unlinkRow{


        @include phone {
            width: 100%;
            justify-content: space-around;
        }

        p{
            font-weight: bold;
        }
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }
}
