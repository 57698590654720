@import '../customer-cp.module.scss';

.container {
    position: relative;

    .loadingContainer {
        position: absolute;
        background-color: grey;
        opacity: 0.6;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        .loader {
            width: 50px;
            padding: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            aspect-ratio: 1;
            border-radius: 50%;
            background: white;
            --_m: conic-gradient(#0000 10%, #000),
                linear-gradient(#000 0 0) content-box;
            -webkit-mask: var(--_m);
            mask: var(--_m);
            -webkit-mask-composite: source-out;
            mask-composite: subtract;
            animation: l3 1s infinite linear;
        }
        @keyframes l3 {
            to {
                transform:  translate(-50%, -50%) rotate(1turn);
            }
        }
    }
}

.modal {
    background-color: white;
    box-shadow: 2px 1px 15px -6px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 1px 15px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 1px 15px -6px rgba(0, 0, 0, 0.75);
    padding: 30px;

    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
        padding: 15px;
        padding-top: 20px;
    }
}

.header {
    margin: 10px 10px 10px 0px;
    h3 {
        color: black !important;
    }
    &.center{
        text-align: center;
    }
}

.rowContainer {
    display: flex;
    flex-direction: column;
    .row {
        width: 100%;
        display: flex;
        align-items: flex-start;

        @include phone{
            flex-direction: column;
        }

        color: black;
        font-weight: 550;
        margin-top: 10px;
        margin-bottom: 10px;
        .label {
            width: calc(20% - 2px);
            color: gray;
            
           @include phone{
            margin-bottom: 10px;
           }
        }

        .content {
            width: 80%;
            display: inline-flex;

            @include phone{
                justify-content: space-between;
                width: 100%;
            }

            * {
            }

            .last {
                margin-left: auto;
                display: flex;
                column-gap: 20px;
            }
        }
    }
}

.modalHeader {
    color: gray;
    font-weight: light;
    margin-top: -10px;
}
