@import '../styles/base-styles.scss';

.quarryContainer {
    @include borders(2px);
    width: 367px;
    height: 188px;
    padding: 10px;
    margin-top: 15px;

    p {
        margin: 0px;
    }

    .quarryHeader {
        margin-bottom: 18px;
        .quarryHeaderTitle {
            @include silveradoFont;
            font-size: 16.8px;
        }
        .quaryHelper {
            @include amplitudeWideBook;
            font-size: 8px;
            width: 240px;
        }
    }

    .quarryInnerContainer {
        display: grid;
        grid-auto-flow: column;
        margin-bottom: 10px;

        &.twoColumn {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(4, 1fr);
            column-gap: 12px;
        }
        &.oneColumn {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(8, 1fr);
        }
    }

    .quarryHorizontalLine {
        @include horizontalLine(1px, 100%, $lineColor);
        height: 26px;

        .quarryLabelContainer {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 15px max-content;
            align-items: center;
        }

        .quarryInput {
            width: 100%;
            height: 100%;
        }

        .quarryLabel {
            @include amplitudeWideBook;
            font-size: 8px;
        }
    }

    &.ark {
        height: 190px;
        width: 260px;
        margin-top: 35px;
        .quarryHeader {
            margin-bottom: 3px;
        }

        .quarryHorizontalLine {
            height: 22px;
            font-size: 8px;
            .quarryLabelContainer {
                grid-template-columns: 15px max-content 1fr;
                .arkNode {
                    margin-left: auto;
                    font-size: 8px;
                }
            }
        }
    }
}
