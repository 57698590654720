@import '../styles/base-styles.scss';

.horizontalLayout {
    width: 760px;
    margin-top: 15px;

    .eventLabel {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;

        .header {
            @include silveradoFont;
            margin: 0;
            font-size: 16.8px;
        }
        .helper {
            @include amplitudeWideBook;
            font-size: 8.5px;
            margin-top: 6px;
        }
    }

    .line {
        display: grid;
        margin: 0;
        grid-template-columns: 270px 250px 1fr;
        padding: 5px 0;
    }

    .horizontalSeparator {
        @include horizontalLine(1px, 760px, $lineColor);
    }

    .container {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 18px 1fr 100px;
        border-right: 1px solid black;
        padding: 0;
        margin-right: 10px;

        &:nth-child(3) {
            border-right: none;
        }

        .regular {
            @include amplitudeWideBook;
            font-size: 8.5px;
            margin: 0;
        }

        .medium {
            @include amplitudeWideMedium;
            font-size: 8.5px;
            margin: 0;

            .icon {
                @include amplitudeWideIcon;
                width: 10px;
                text-align: center;
                margin-right: 5px;
                font-size: 7px;
            }
        }
    }
}

.verticalLayout {
    width: 252px;

    .mileStoneStoryEventLabel {
        display: flex;
        flex-direction: column;
        gap: 1px;
        margin-bottom: 5px;

        .mileStoneHeader {
            @include silveradoFont;
            margin: 0;
            font-size: 16.8px;
        }

        .mileStoneHelper {
            @include amplitudeWideBook;
            font-size: 8.5px;
        }
    }

    .mileStoneLine {
        display: flex;
        flex-direction: column;
        margin: 0;
        border-top: 1px solid $lineColor;

        .mildStoneContainer {
            display: grid;
            height: 23px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            grid-template-columns: 18px 1fr 95px;
            padding: 0;
            border-bottom: 1px solid $lineColor;

            .mileStoneRegular {
                @include amplitudeWideBook;
                font-size: 8.5px;
                margin: 0;
            }

            .mildStoneInput {
                height: 22px;
                width: 252px;
            }

            .mileStoneMedium {
                @include amplitudeWideMedium;
                font-size: 8.5px;
                margin: 0;

                span.milestoneIcon {
                    @include amplitudeWideIcon;
                    width: 10px;
                    text-align: center;
                    margin-right: 5px;
                    font-size: 7px;
                }
            }
        }
    }
}
