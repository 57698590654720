.sideBySideContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notesContainer {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 243px;

    textarea {
        resize: none;
        margin: 10px;
        height: 170px;
    }

    p.title {
        color: white;
        font-size: 24px;
    }
}

.mainParent {
    display: flex;
    background-color: black;
    flex-direction: column;
    width: 640px;
    padding-bottom: 10px;

    .title {
        color: white;
        font-size: 24px;
    }
}

.gearContainer {
    display: flex;
    flex-wrap: wrap;
}

.child {
    flex: 1 0 calc(25% - 20px); /* explanation below */
    margin: 10px;
    height: 43px;
    display: inline-flex;
    flex-direction: column;

    label {
        color: white;
    }
}
