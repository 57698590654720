.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .innerContainer {
        background: #fff;
        border: 0 solid;
        border-radius: 5px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        height: 100px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 350px;
        min-height: 100px;
        outline: 1px solid gray;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: 0.3s;
        -webkit-transition: 0.3s;
        width: 100%;
        z-index: 10001;

        .middleContainer{
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
}
