@import '../../sheetBase.scss';

.lowerHalfContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.header {
    display: flex;
    flex-direction: column;

    .headerTitle {
        @include silveradoFont;
        font-size: 16.8px;
    }

    .headerHelper {
        @include amplitudeWideBook;
        font-size: 8px;
    }
}

.settlementContainer {
    margin-top: 10px;
    .header {
        margin-bottom: 4px;
    }
}

.principlesContainer {
    .principlesHorizontalLine {
        width: 366px;
        @include horizontalLine(1px, 366px, $lineColor);

        .principleInput {
            height: 100%;
            width: 100%;
        }
    }
}

.quarryContainer {
    .quarryEmptyLine {
        display: flex;
        width: 165px;
        height: 25px;
        border-bottom: 1px solid $lineColor;

        .emptyLineInput {
            height: 20px;
            width: 252px;
        }
    }
}
