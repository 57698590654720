@import '../styles/base-styles.scss';

.settlementLocationsContainer {
    .settlementLocationsHeader {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 5px;

        .settlementLocationsHeaderTitle {
            @include silveradoFont;
            font-size: 16.8px;
            margin: 0;
        }

        .settlementLocationsHelper {
            @include amplitudeWideBook;
            font-size: 8px;
            margin: 0;
        }
    }

    .settlementLocationInnerContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(9, 1fr);
        grid-auto-flow: column;
    }

    .settlementLocationsHorizontalLine {
        @include horizontalLine(1px, 170px, $lineColor);
        display: flex;
        align-items: center;
        gap: 2px;
        height: 17px;

        .smallCheckbox {
            width: 8px;
            height: 8px;
        }
        .settlementLocation {
            @include amplitudeWideBook;
            font-size: 8px;
            width: 100px;
            line-height: 16.8px;
            margin: 0px;
        }
    }

    &.ark {
        .settlementLocationsHorizontalLine {
            height: 19px;
        }
        .settlementLocationInnerContainer {
            grid-template-rows: repeat(11, 1fr);
        }
    }
}
