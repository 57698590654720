$shopify-base-color: #95bf47;
$shopify-dark-color: #64943e;

.shopifyIcon {
    background-image: url(../../../../assets/icons/shopify_blank.svg);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    margin: 2px;
}

.shopifyButton {
    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2px;
    }

    appearance: none;
    background-color: $shopify-base-color;
    border: 1px solid $shopify-dark-color;
    border-radius: 6px;
    box-shadow: $shopify-dark-color 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;

    &:focus:not(:focus-visible):not(.focus-visible) {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        background-color: $shopify-dark-color;
    }

    &:focus {
        box-shadow: $shopify-dark-color 0 0 0 3px;
        outline: none;
    }

    &:disabled {
        background-color: #c4c4c4;
        border-color: rgba(27, 31, 35, 0.1);
        color: rgb(255, 255, 255);
        cursor: not-allowed;

        &:active {
            background-color: #c4c4c4;
            box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
        }
    }

    &.fakeDisable{
        background-color: #c4c4c4;
        border-color: rgba(27, 31, 35, 0.1);
        color: rgb(255, 255, 255);
        cursor: not-allowed;

        &:active {
            background-color: #c4c4c4;
            box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
        }
    }

    &:active {
        background-color: $shopify-base-color;
        box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
    }
}
