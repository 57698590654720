$innerTop: 52px;
$innerBottom: 52px;
$innerLeft: 45px;
$innerRight: 45px;
$lineColor: #878484;

@media print {
    html,
    body {
        //height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
    
    }
}

input {
    border: none;
    outline: none;
}

@font-face {
    font-family: 'SilveradoRR LightCond';
    src: url(../../../../assets/fonts/SVCL____.TTF) format(truetype);
}

@font-face {
    font-family: 'AmplitudeWide-Regular';
    src: url(../../../../assets/fonts/AmplitudeWide-Regular.ttf)
        format('truetype');
}

@font-face {
    font-family: 'AmplitudeWide-Medium';
    src: url(../../../../assets/fonts/AmplitudeWide-Medium.ttf)
        format('truetype');
}

@font-face {
    font-family: 'AmplitudeWide-Book';
    src: url(../../../../assets/fonts/AmplitudeWide-Book.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'AmplitudeWide-Bold';
    src: url(../../../../assets/fonts/AmplitudeWide-Bold.ttf)
     format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'AmplitudeWide-Book-KDIcon';
    src: url(../../../../assets/fonts/amplitudewide-book-kdicon.otf)
        format('opentype');
}

@mixin borders($borderWidth) {
    -webkit-box-shadow: inset 0px 0px 0px $borderWidth black;
    -moz-box-shadow: inset 0px 0px 0px $borderWidth black;
    box-shadow: inset 0px 0px 0px $borderWidth black;
}

@mixin silveradoFont {
    font-family: 'SilveradoRR LightCond';
}

@mixin amplitudeWideRegular {
    font-family: 'AmplitudeWide-Regular';
}

@mixin amplitudeWideMedium {
    font-family: 'AmplitudeWide-Medium';
}

@mixin amplitudeWideBook {
    font-family: 'AmplitudeWide-Book';
}

@mixin amplitudeWideBold {
    font-family: 'AmplitudeWide-Bold';
}

@mixin amplitudeWideIcon {
    font-family: 'AmplitudeWide-Book-KDIcon';
}

@mixin verticalLine($lineWidth: 2px, $lineHeight: 20px, $lineColor: black) {
    border-left: $lineWidth solid $lineColor;
    height: $lineHeight;
}

@mixin horizontalLine($lineWidth: 2px, $width: 20px, $color: black) {
    border-bottom: $lineWidth solid $color;
    width: $width;
}

@mixin blackRectangle {
    width: 8px;
    height: 8px;
    box-shadow: inset 0 0 0 1000px black;
}

.header {
    display: flex;
    flex-direction: column;

    .headerTitle {
        @include silveradoFont;
        font-size: 16.8px;
    }

    .headerHelper {
        @include amplitudeWideBook;
        font-size: 8px;
    }
}
