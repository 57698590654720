@import '../../sheetBase.scss';

.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
}

.emptyLine {
    display: grid;
    height: 23px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 18px 1fr 95px;
    padding: 0;
    border-bottom: 1px solid $lineColor;

    .emptyLineInput {
        height: 22px;
        width: 252px;
    }
}

.quarryEmptyLine {
    display: flex;
    width: 240px;
    height: 20px;
}

.sheetMileStonesContaineer {
    margin-top: 10px;
}

.arkBonuses {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .bonusesTitle {
        font-weight: bold;
    }
    .bonusesSubTitle {
        font-size: 8px;
    }

    .bonusesHorizontalLine {
        margin-top: 3px;
        @include horizontalLine(2px, 100%, black);
    }
}

.nemesisMonstersContainer {
    @include borders(1px);
    height: 190px;
    width: 254px;
    padding: 10px;
    position: relative;

    .horizontalLine {
        position: absolute;
        @include horizontalLine(1px, 230px, $lineColor);
    }

    .nemesisCheckboxContainer {
        width: 8px;
        height: 8px;
    }

    .nemesisMonsterRow {
        width: 230px;
        height: 23px;
        position: absolute;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .nemesisMonster {
            @include amplitudeWideBook;
            font-size: 8px;
            width: 120px;
            position: relative;
            left: 12px;
        }

        .nemesisLvl {
            @include amplitudeWideBook;
            position: absolute;
            font-size: 8px;
        }

        .nemesisInput{
            margin-left: 10px;
            width: 100px;
        }
    }

    &.ark {
        @include borders(2px);
        height: 200px;
        width: 260px;
        margin-top: 10px;
    }
}
